import * as React from "react"

import { experimentalStyled as styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const LanguagesGrid = (props) => {
    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 16 }} style={{ marginBottom: "20px" }}>
            {props.skills.map((skill, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                    <Item>
                        <Avatar src={skill.image} variant="square" style={{ 
                            display: "inline-block", 
                            verticalAlign: "middle", 
                            marginRight: "5px" }} 
                        />
                        <Typography variant="subtitle1" component="span" sx={{ display: { xs: "block", sm: "initial" } }}>
                            {skill.title}
                        </Typography>
                    </Item>
                </Grid>
            ))}
        </Grid>
    )
}

export default LanguagesGrid
