import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import WorkHistory from "../components/workHistory"
import LanguagesGrid from "../components/languagesGrid"

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const ResumePage = () => (
  <Layout>
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Seo title="Resume" contentType="profile" firstName="Nathan" lastName="Duff" gender="male" profileImage="/static/images/nate.jpg" />
        <Typography variant="h2" component="h2" gutterBottom>
          Resume
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          About Me
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} xl={9}>
              <Grid item xs={3} xl={0} sx={{ alignSelf: { sm: "center" }, display: { xl: "none" } }} style={{ maxWidth: "100%" }}>
                <StaticImage
                  src="../images/nate.jpg"
                  alt="Nate Duff"
                  style={{ borderRadius: "100px", float: "right", height: "150px", width: "150px" }}
                />
              </Grid>
              <Grid item xs={9} xl={12} style={{ display: "contents" }}>
                <p>
                  I am a Michigan State University Spartan Alumni, born and raised in Metro Detroit, Michigan. I am happily married, I have 2 dogs, and I am one of those lucky people who loves what they do for work enough that it is also a hobby. I have been building webistes since I was old enough to register a domain on GoDaddy and dream about selling lacrosse stick stringing services online, but my itch for programming did not come until my first job out of college in Software Testing for General Motors where I worked heavily in SharePoint and Team Foundation Server.
                </p>
                <p>
                  Once I was automating build and release pipelines in Azure DevOps using Windows PowerShell and writing performance tests I developed an interest in the solutions that I had been testing and releasing. After some time in DevOps and Software Development, I left General Motors and went to work for a small software development company called MercuryWorks where I continued developing and managing solutions in Azure &amp; Azure DevOps. This role also came with infrastructure management experience, Microsoft 365 management experience, on-prem to M365 migration experience, Power Platform solution development experience, and much much more. After a few years I decided to focus more on Azure &amp; Azure DevOps and transitioned to OneStream Software.
                </p>
              </Grid>
              <p>
                I am currently a Senior Cloud Developer at OneStream Software - building, automating, and managing solutions in Azure and Azure DevOps to support the Cloud organization. My first role at OneStream was in Cloud Engineering, where I automated customer infrastructure provisioning using Terraform and Azure Pipelines. Before eventually transitioning to the Cloud Development team to assist with .NET solution development &amp; testing, I also spent some time on the Cloud Operations team to extend the customer provisioning pipeline into an automated disaster recovery validation pipline.
              </p>
            </Grid>
            <Grid item xs={0} xl={3} sx={{ alignSelf: { sm: "center" }, display: { xs: "none", xl: "inherit" } }}>
              <StaticImage
                src="../images/nate.jpg"
                alt="Nate Duff"
                style={{ height: { sm: "88%" }, width: { sm: "70%" }, borderRadius: "100px" }}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography variant="h4" component="h2" gutterBottom sx={{ marginTop: { xs: "50px", sm: "inherit" } }}>
          Certifications
        </Typography>
        <ImageList id="certificationList" sx={{ width: 800, height: 450, alignItems: 'center' }} cols={4} rowHeight={"auto"}>
          <ImageListItem>
            <StaticImage
              src="../images/certs/MCSA-Web-Applications-2019.png"
              alt="MCSA: Web Applications"
              layout="constrained"
            />
          </ImageListItem>
          <ImageListItem>
            <StaticImage
              src="../images/certs/azure-administrator-associate-600x600.png"
              alt="Azure Admin Associate"
              layout="constrained"
            />
          </ImageListItem>
          <ImageListItem>
            <StaticImage
              src="../images/certs/CERT-Expert-DevOps-Engineer-600x600.png"
              alt="Azure Admin Expert"
              layout="constrained"
            />
          </ImageListItem>
          <ImageListItem>
            <StaticImage
              src="../images/certs/microsoft365-teamwork-administrator-associate-600x600.png"
              alt="M365 Teamwork Admin Associate"
              layout="constrained"
            />
          </ImageListItem>
          <ImageListItem>
            <StaticImage
              src="../images/certs/microsoft365-enterprise-adminstrator-expert-600x600.png"
              alt="M365 Enterprise Admin Expert"
              layout="constrained"
            />
          </ImageListItem>
          <ImageListItem>
            <StaticImage
              src="../images/certs/azure-solutions-architect-expert-600x600.png"
              alt="Azure Solutions Architect Expert"
              layout="constrained"
            />
          </ImageListItem>
          {/* <ImageListItem>
            <StaticImage
              src={"../images/certs/EXAM-Expert-AZ-303-600x600.png"}
              alt={"ITILv4"}
              layout="fixed"
            />
          </ImageListItem> */}
        </ImageList>
        <Typography variant="h4" component="h2" gutterBottom>
          Work History
        </Typography>
        <WorkHistory />
        {/* <Typography variant="h4" component="h2" gutterBottom>
          Education
        </Typography>
        <Typography variant="subtitle" gutterBottom component="div">
          Michigan State University - B.A (2015)
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div" style={{ paddingLeft: "10%" }}>
          Major: Communications
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div" style={{ paddingLeft: "10%" }}>
          Minor: Information Technology
        </Typography> */}
        <Typography variant="h4" component="h2" gutterBottom sx={{ marginTop: "20px" }}>
          Languages &amp; Technologies
        </Typography>
        <Typography variant="h5" component="h3" gutterBottom>
          High Proficiency
        </Typography>
        <LanguagesGrid skills={[
          {
            title: "PowerShell",
            image: "https://devblogs.microsoft.com/powershell/wp-content/uploads/sites/30/2018/09/Powershell_256.png"
          },
          {
            title: "Azure DevOps",
            image: "/favicon.ico"
          },
          {
            title: ".NET/C#",
            image: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Csharp_Logo.png"
          },
          {
            title: "JavaScript",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/480px-Unofficial_JavaScript_logo_2.svg.png"
          },
          {
            title: "Azure",
            image: "https://swimburger.net/media/ppnn3pcl/azure.png"
          },
          {
            title: "Selenium",
            image: "https://iconape.com/wp-content/png_logo_vector/selenium-logo.png"
          },
          {
            title: "MS SharePoint",
            image: "https://avatars.githubusercontent.com/u/19156602?s=280&v=4"
          },
          {
            title: "MS Teams",
            image: "https://b.thumbs.redditmedia.com/1GWle1GftsjZEhPRVoT5q-bH4DZHhXejcEqIRladfSY.png"
          }
        ]} />
        <Typography variant="h5" component="h3" gutterBottom>
          Medium Proficiency
        </Typography>
        <LanguagesGrid skills={[
          {
            title: "Docker",
            image: "https://clouddayscom.files.wordpress.com/2020/06/docker-logo.png"
          },
          {
            title: "GatsbyJS",
            image: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/pajbqv1x9twf1nh7lxpg"
          },
          {
            title: "SQL",
            image: "https://techcommunity.microsoft.com/t5/image/serverpage/image-id/211362iF5BA8C87CD4C2D9F?v=v2"
          },
          {
            title: "CSS",
            image: "https://contactmentor.com/wp-content/uploads/2021/06/css3img.png"
          }
        ]} />
        <Typography variant="h5" component="h3" gutterBottom>
          Low Proficiency
        </Typography>
        <LanguagesGrid skills={[
          {
            title: "Kubernetes",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/1200px-Kubernetes_logo_without_workmark.svg.png"
          },
          {
            title: "MySQL",
            image: "https://www.freepnglogos.com/uploads/logo-mysql-png/logo-mysql-linux-sbc-bash-scripting-1.png" //"https://cdn.iconscout.com/icon/free/png-256/mysql-3628940-3030165.png" //"https://cdn.iconscout.com/icon/free/png-256/mysql-2749310-2284700.png"
          }
        ]} />
      </Box>
    </Container>
  </Layout>
)

export default ResumePage
