//workHistory
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './workHistory.css'

export default function WorkHistory() {
  return (
    <React.Fragment>
      <Timeline position="right" className="workHistoryTimeline" sx={{ marginLeft: { xs: "-18%", sm: "-70%", md: "-75%" }, marginRight: { xs: "0%", sm: "-5%", md: "inherit" } }}>
      <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2022 - Now
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>SENIOR CLOUD DEVELOPER - ONESTREAM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                      Full stack .NET/Azure development.
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
      <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2021 - 2022
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>CLOUD DEVELOPER - ONESTREAM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                      Full stack .NET/Azure development.
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2021 - 2021
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>CLOUD OPERATIONS ENGINEER - ONESTREAM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        For our company, I manage our Office 365 applications, intranet, and extranet for sharing information either internally or with clients. I also operate the Azure DevOps Boards, Pipelines, and Repos while defining processes and standards related to deployment and transitioning to operations and support for both internal and external applications.
                    </Typography>
                    <Typography>
                        For our clients, I perform Office 365 development and consulting, handle Level 2 Support tickets, Azure infrastructure management, and Azure DevOps pipeline management. Our team is also responsible for the management of our internal infrastructure that houses our application logging and automated test resources for client applications. We review Azure environments for opportunities for cost savings, high availability and solution architecture durability improvements, and any recommendations we can provide regarding cloud best practices.
                    </Typography>
                    <Typography>
                    Skills Required: Seq (logging), Selenium Grid for Automated Testing, Visual Testing using AppliTools, SonarQuebe, Azure, Azure DevOps, Git, GitFlow, Zendesk, Office 365 Administration, Office 365 Security &amp; Compliance, Exchange Online, SharePoint Online, Microsoft Teams, PowerBI, PowerApps, Microsoft Flow, SQL, MySQL, Wordpress, WP Engine
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2020 - 2021
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>CLOUD DEVOPS ENGINEER - ONESTREAM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        For our company, I manage our Office 365 applications, intranet, and extranet for sharing information either internally or with clients. I also operate the Azure DevOps Boards, Pipelines, and Repos while defining processes and standards related to deployment and transitioning to operations and support for both internal and external applications.
                    </Typography>
                    <Typography>
                        For our clients, I perform Office 365 development and consulting, handle Level 2 Support tickets, Azure infrastructure management, and Azure DevOps pipeline management. Our team is also responsible for the management of our internal infrastructure that houses our application logging and automated test resources for client applications. We review Azure environments for opportunities for cost savings, high availability and solution architecture durability improvements, and any recommendations we can provide regarding cloud best practices.
                    </Typography>
                    <Typography>
                    Skills Required: Seq (logging), Selenium Grid for Automated Testing, Visual Testing using AppliTools, SonarQuebe, Azure, Azure DevOps, Git, GitFlow, Zendesk, Office 365 Administration, Office 365 Security &amp; Compliance, Exchange Online, SharePoint Online, Microsoft Teams, PowerBI, PowerApps, Microsoft Flow, SQL, MySQL, Wordpress, WP Engine
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2019 - 2020
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>DEVOPS CLOUD ENGINEER - MERCURYWORKS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        For our company, I manage our Office 365 applications, intranet, and extranet for sharing information either internally or with clients. I also operate the Azure DevOps Boards, Pipelines, and Repos while defining processes and standards related to deployment and transitioning to operations and support for both internal and external applications.
                    </Typography>
                    <Typography>
                        For our clients, I perform Office 365 development and consulting, handle Level 2 Support tickets, Azure infrastructure management, and Azure DevOps pipeline management. Our team is also responsible for the management of our internal infrastructure that houses our application logging and automated test resources for client applications. We review Azure environments for opportunities for cost savings, high availability and solution architecture durability improvements, and any recommendations we can provide regarding cloud best practices.
                    </Typography>
                    <Typography>
                    Skills Required: Seq (logging), Selenium Grid for Automated Testing, Visual Testing using AppliTools, SonarQuebe, Azure, Azure DevOps, Git, GitFlow, Zendesk, Office 365 Administration, Office 365 Security &amp; Compliance, Exchange Online, SharePoint Online, Microsoft Teams, PowerBI, PowerApps, Microsoft Flow, SQL, MySQL, Wordpress, WP Engine
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2018 - 2019
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>PRODUCT INNOVATION DEVELOPMENT - GENERAL MOTORS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        In this role, I manage the builds and deployments for our development teams. I have decreased deployment time and costs with 100% of releases successfully implemented.
                    </Typography>
                    <Typography>
                        Skills Required: Release Management, CI/CD, Pivotal Cloud Foundry containerized application development, .NET Core, PowerShell scripting, SharePoint development, Team Foundation Server / Azure DevOps
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2017 - 2018
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>DEVOPS ENGINEER - GENERAL MOTORS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        In this role, I supported a variety of .NET and SharePoint internal business applications, engineering solutions for automated deployments, and assumed the change coordinator role responsibilities in our ITOC process.
                    </Typography>
                    <Typography>
                        Skills Required: PowerShell scripting, SharePoint development, Team Foundation Server, incident management
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2015 - 2017
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>SOFTWARE TEST ANALYST - GENERAL MOTORS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        In this role, I performed functional and non-functional testing GM applications. I learned to follow both agile and waterfall software development life cycles, working closely with business analysts and project managers using Team Foundation Server to write user stories, create test plans, test suites, and test cases.
                    </Typography>
                    <Typography>
                        Skills Required: Web development, functional testing, automated testing, performance testing, mobile testing
                    </Typography>
                </AccordionDetails>
            </Accordion>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  );
}
